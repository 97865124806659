/** @format */

import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Clock } from "react-bootstrap-icons";
function DetalleRecetas({ id }) {
  const [receta, setReceta] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(null);
    fetch(`${process.env.API_URL_CHAPIN}/ChapinSinGlutenWS/rest/chapinWS/receta/${id}`)
      .then((result) => result.json())
      .then((result) => {
        setLoading(false);
        setReceta(result);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {loading && <div>Espera un momento, estamos obteniendo la informacion de la receta...</div>}
      {error && (
        <div>
          <br />
          <br />
          <br />
          <br />
          Ups!! Ocurrio un error al obtener la receta, intenta recargar la página nuevamente.
          <br /> Si el problema persiste contacta al administrador del sitio.
        </div>
      )}
      {receta && (
        <>
          <Link to="/recetas/"> &nbsp;&lt;&nbsp; Regresar a recetas</Link>
          <br />
          <br />

          <div className="contenedor-detalle-recetas">
            <div className="contenedor-detalle-recetas-div">
              <img
                className="contenedor-detalle-recetas-img"
                src={`${process.env.API_URL_CHAPIN}` + receta.url}
                alt={receta.nombreReceta}
              />
            </div>

            <div className="detalle-receta-container">
              <div className="detalle-receta-contenido ">
                <div className="detalle-receta-contenido-titulo-posicion">
                  <div className="detalle-receta-contenido-titulo ">
                    <div className="recetas-detalle-componente-titulo">{receta.nombreReceta}</div>
                    <div className="recetas-detalle-componente-subtitulo">{receta.productoReceta}</div>
                    <div className="recetas-detalle-componente-descripcion-receta">{receta.descripcionReceta}</div>
                    <div className="recetas-detalle-componente-tiempo">
                      <Clock />
                      {`   ${receta.tiempoPreparacion}`}
                    </div>
                  </div>
                  {receta.urlVideo.length > 0 && (
                    <iframe
                      title="Video"
                      width="90%"
                      height="160px"
                      src={`${receta.urlVideo}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  )}
                </div>
              </div>

              <div className="detalle-receta-contenido">
                <div className="detalle-receta-contenido-titulo-ingredientes">Ingredientes</div>
                <div className="detalle-receta-contenido-padding">
                  {receta.ingredientes.map((e, key) => {
                    if (e.startsWith("*")) {
                      return (
                        <div className="detalle-receta-contenido-ingredientes">
                          <Link
                            key={receta.productoPrincipal}
                            to={`/productos/detalle-producto/${receta.productoPrincipal}`}
                          >
                            {e.replace("*", "")}{" "}
                            <StaticImage src="../images/shopping_car.png" alt="Shoping car image" width={15} />
                          </Link>
                        </div>
                      );
                    } else {
                      return <div className="detalle-receta-contenido-ingredientes">{e}</div>;
                    }
                  })}
                </div>
              </div>

              <div className="detalle-receta-contenido">
                <div className="detalle-receta-contenido-titulo-preparacion">Preparación</div>
                <div className="detalle-receta-contenido-padding" dangerouslySetInnerHTML={{ __html: receta.receta }} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DetalleRecetas;
